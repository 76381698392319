import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  TextField,
  IconButton,
  InputAdornment, Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useStore } from 'src/store/Store';
import { ProfileService, SiteConfigService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------
import { Grid, Container, Card, CardContent, Button, Typography } from '@mui/material';
import Logo from "../../Logo";
import BaseCard from "../../BaseCard";
import { useTheme } from '@mui/styles';
// import { useMsal } from '@azure/msal-react';
import { msalInstance } from 'src/msalConfig'; // The MSAL instance

export default function LoginForm() {
  const { t } = useTranslation();
  const location = useLocation();
  // const { instance } = useMsal();  // MSAL instance for Microsoft login

  const queryParams = new URLSearchParams(location.search);
  const [invitation, setAcceptInvitation] = useState(queryParams.get('accept_invitation'));

  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loginMessage, setLoginMessage] = useState(t('user-not-found'));
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [logo, setLogo] = useState("");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    console.log(invitation);
    if (invitation == 'true') {
      setLoginMessage(t('Password has been created successfully. You can login now.'));
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    if (store.logo) {
      setLogo(store.logo);
    }
  }, [store.manualUpdateTriggeredForPalette, store.logo]);


  const isMobile = width <= 768;



  const fetchSettings = async (loginResponse) => {

    const settingsResponse = await SiteConfigService.getStyle();

    const serviceStatuses = settingsResponse.data.data;

    dispatch({
      type: "SET_SERVICE_STATUSES",
      payload: {
        services: serviceStatuses
      }
    });
    dispatch({
      type: "LOGIN",
      payload: {
        token: loginResponse.data.data.access_token,
        expires_at: loginResponse.data.data.expires_at,
        refresh_token: loginResponse.data.data.refresh_token,
        roles: loginResponse.data.data.roles,
        meta: loginResponse.data.meta,
        detail: loginResponse.data.data.detail,
        admin: false,
      }
    });
    dispatch({
      type: "UPDATE_SELECTED_LANGUAGE",
      payload: {
        //language: loginResponse.data.user.selected_language_code,
        language: "en-US",
      }
    });
    navigate('/', { replace: true });


  };

  const getFormData = (values) => {
    return {
      email: values.email,
      password: values.password,
    }
  }


  const LoginSchema = Yup.object().shape({
    password: Yup.string().required(t('password-is-required'))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      const payload = getFormData(values);

      ProfileService.login(payload)
        .then((response) => {
          console.log(response);

          if (response.status !== 200) {
            throw "login failed";
          }
          if (response.data.meta.code != 200) {
            throw { message: response.data.meta.msg };
          }

          fetchSettings(response);

        })
        .catch((err) => {
          dispatch({ type: "LOGIN_FAIL" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  
  const handleMicrosoftLogin = async () => {
    try {
        const response = await msalInstance.loginPopup({ scopes: ["User.Read"] });

        if (response) {
            ProfileService.login_sso({
                idToken: response.idToken,
                accessToken: response.accessToken
            })
            .then((ssoResponse) => {
                if (ssoResponse.status !== 200) {
                    console.error("SSO login failed with status:", ssoResponse.status);
                    throw new Error("Login failed");
                }

                if (ssoResponse.data.meta.code !== 200) {
                    console.error("SSO login failed with message:", ssoResponse.data.meta.msg);
                    throw new Error(ssoResponse.data.meta.msg);
                }
                fetchSettings(ssoResponse);
            })
            .catch((err) => {
                console.error("Error during SSO login process:", err);
                dispatch({ type: "LOGIN_FAIL" });
            })
            .finally(() => {
                console.log("SSO login process finished.");
                // setSubmitting(false);
            });
        } else {
            console.warn("No response received from Microsoft login.");
        }

    } catch (e) {
        console.error("Error during Microsoft login:", e);

        if (e.errorCode === "user_cancelled") {
            console.log("User cancelled the login popup.");
            alert("User cancelled the window");
        } else if (e.errorCode === "interaction_in_progress") {
            console.log("Login interaction already in progress.");
            alert("There is already an open window");
        } else {
            console.error("Unexpected error during login popup:", e);
            alert("Cannot initiate login popup! Please check browser pop-up permissions.");
        }
    }
  };

  const openRegisterForm = () => {
    navigate('/register');
  }
  const openForgotPasswordForm = () => {
    navigate("/reset-password-request");
  }

  const theme = useTheme();

  return (
    <>
      <BaseSnackbar open={openSnackbar} message={loginMessage} setOpen={setOpenSnackbar} />
      <Container maxWidth="xs"
        sx={isMobile ? {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        } : { position: "absolute", left: "5%", bottom: "12vh" }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Grid item xs={10} sm={7} md={5} lg={4} xl={4} sx={{ display: 'table', height: '100%' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              component={Card}
              bgcolor={'red'}
            >
              <BaseCard>
                <CardContent sx={{
                  marginX: 1
                }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Logo sx={{ width: 200 }} src={logo} /></Box>

                  <Grid item mb={1}>
                    <Typography mb={3} sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '24px', color: theme.palette['--main-text-color'] }}>
                      DID Management
                    </Typography>
                    <FormikProvider value={formik}>
                      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                        <TextField
                          fullWidth
                          autoComplete="email"
                          type="email"
                          label={t('email-address')}
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email} sx={{ marginBottom: 2 }}
                        />
                        <TextField
                          fullWidth
                          autoComplete="current-password"
                          type={showPassword ? 'text' : 'password'}
                          label={t('password')}
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }} sx={{ marginBottom: 3 }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting} sx={{
                            '&:hover': {
                              backgroundColor: theme.palette['--color-primary']
                            }, backgroundColor: theme.palette['--color-primary'], marginBottom: 1
                          }}
                        >
                          {t('login')}
                        </LoadingButton>
                      </Form>
                    </FormikProvider >

                    {/* Microsoft Login Button */}
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      sx={{
                        marginBottom: 2,
                        backgroundColor: theme.palette['--color-primary'],
                        '&:hover': {
                          backgroundColor: theme.palette['--color-primary-hover']
                        }
                      }}
                      onClick={handleMicrosoftLogin}
                    >
                      {t('login-with-microsoft')}
                    </Button>

                    <Button
                      size="large"
                      type="submit"
                      variant="outlined"
                      sx={{
                        '&:hover': {
                          color: theme.palette['--color-search'], borderColor: theme.palette['--color-search']
                        }, marginBottom: 1, color: theme.palette['--color-search'], borderColor: theme.palette['--color-search']
                      }}
                      onClick={openRegisterForm}
                      fullWidth>
                      Register
                    </Button>
                    <Button variant="text" underline sx={{ color: theme.palette['--color-primary'] }} fullWidth onClick={openForgotPasswordForm}>
                      Forgot Password
                    </Button>
                  </Grid>
                </CardContent>
              </BaseCard>
            </Grid>
          </Grid>
        </Grid>
      </Container>


    </>
  );
}
